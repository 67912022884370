import React from "react";
import "./index.css";
import { ImageUrl } from "../../API/baseUrl";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Box, Typography, Button } from '@mui/material';
import { slugify } from "../../Componentsnew/URL/Index";
const HoomepageSlider2 = ({ titleList }) => {
  // Limit the number of items to 3
  const limitedTitleList = titleList.slice(0, 3);
  const navigate = useNavigate();

  const handleDetailPage = (id, name) => {
    const cleanedName = slugify(name);
    navigate(`/product/${id}/${cleanedName}`);
  };


  return (
    <Box className="HomepageSliderCon2" sx={{ p: 2 }}>
      <Grid container spacing={3}>
        {limitedTitleList.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={10}
            md={4}
            key={index}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Box className="box box1 " sx={{
              width: '100%', maxWidth: '450px', minHeight: "200px", p: 2,
              backgroundColor: '#f0f0f0;',
              cursor: "pointer"
            }}
     
              onClick={() => handleDetailPage(item.category_id, item.category_name)}
            >

              <Box className="box-content" sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '250px', mr: 2 }}>
                  <Typography variant="h6" component="p">
                    {item.category_name}
                  </Typography>
                  <span to="/product" className="DiscovernowBNT">
                    Discover now
                  </span>
                </Box>
                <Box>
                  <img
                    src={`${ImageUrl}${item.files}`}
                    alt={item.category_name}
                    className="homepagesliderimage"
                    style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Box>
              </Box>

            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

};

export default HoomepageSlider2;
