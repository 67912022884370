import React, { useEffect, useState } from "react";
import headerlogo from "../../Assect/logo.png";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import Login from "../../Pages/login/login";
import { Divider, InputLabel, NativeSelect, FormControl } from "@mui/material";
import { GoXCircle } from "react-icons/go";
import { CategoryAndSubcategoryApi, getApiCall } from "../../API/baseUrl";
import { RxCross2 } from "react-icons/rx";
import { slugify } from "../URL/Index";

const MobileMenu = ({ onClick, setShowmblMenu, setCatval }) => {
  const user_id = localStorage.getItem("user_id");
  const [showloginpopup, setShowloginpopup] = useState(false);
  const [categories, setCategories] = useState([]); // Renamed state variable
  const navigate = useNavigate()

  const closemenu = () => {
    setShowmblMenu(false);
    setCatval("");
  };

  const handleprofilePage = () => {
    setShowloginpopup(!showloginpopup);
  };

  const fetchsetCategoryList = async () => {
    try {
      const result = await getApiCall(CategoryAndSubcategoryApi);
      if (result.data.status) {
        setCategories(result.data.data);
        console.log("CategoryList", result.data.data[0].category_name);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchsetCategoryList();
  }, []);

  const handleNavigate = (event) => {

    const selectedCategory = JSON.parse(event.target.value);
    const { id, category_name } = selectedCategory;
    const Id = slugify(id)
    const Category_name = slugify(category_name)
    console.log("Selected Category ID:", Id);
    console.log("Selected Category Name:", Category_name);

    navigate(`product/${Id}/${Category_name}`);
    closemenu();
  };

  return (
    <>
      <aside className="mbl_menu_container">
        <div className="mbl_menu_container_inside">
          <div onClick={onClick}
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <RxCross2 className="mbl_menu_close_icon"
              style={{
                fontSize: "20px",
                textAlign: "end",
                cursor: "pointer",

              }}
            />
          </div>

          <div className="mbl_menu_items">
            <ul className="mbl_menu_ul_list">
              <li className="mbl_menu_li">
                <Link to="/" onClick={closemenu}>
                  Home
                </Link>
              </li>
              <Divider />



              <Divider />
              <li className="mbl_menu_li">
                <Link to="/liked" onClick={closemenu}>
                  Wishlist
                </Link>
              </li>
              <Divider />
              <li className="mbl_menu_li">
                <Link to="/contact" onClick={closemenu}>
                  Contact Us
                </Link>
              </li>
              <Divider />
              <li className="mbl_menu_li">
                <Link to="/about" onClick={closemenu}>
                  About Us
                </Link>
              </li>
              <Divider />
              <li style={{ color: " #c1bcbc" }}>
                <Link to={"/sitemap.html"} onClick={closemenu}>SiteMap </Link>
              </li>
              <Divider />
              <li className="mbl_menu_li">
                <select
                  className="dropdown"
                  name="category"
                  onChange={(event) => handleNavigate(event)}
                  style={{
                    border: 'none',
                    padding: '10px',
                    color: 'black',
                    fontWeight: 500,
                    fontSize: '16px',
                    textTransform: 'uppercase',
                    width: "180px",
                    border: "none",
                    padding: "10px 0px 10px 0px",
                    background: "none"
                  }}
                >
                  {/* Placeholder */}
                  <option value="" disabled selected className="placeholder-option">
                    Products
                  </option>

                  {/* Map Categories */}
                  {categories.map((category) => (
                    <option
                      key={category.id}
                      value={JSON.stringify({ id: category.id, category_name: category.category_name })}
                      className="select-option"
                    >
                      {category.category_name}
                    </option>
                  ))}
                </select>
              </li>

              {/* Category Dropdown */}




            </ul>
          </div>
        </div>
      </aside>

      {showloginpopup && (
        <Login
          showloginpopup={showloginpopup}
          setShowloginpopup={setShowloginpopup}
        />
      )}
    </>
  );
};

export default MobileMenu;
