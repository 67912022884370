import './index.css'
import { useEffect, useState } from "react";
import { getApiCall, getTermsandCondition } from '../../API/baseUrl';
import RemoveTag from '../../components/removetag';
import { Box, Typography } from '@material-ui/core';


const Privacy = () => {
  const [getTermsData, setGetTermsData] = useState('')


  const getTermsCondition = async () => {
    const id = 1
    try {
      const result = await getApiCall(`${getTermsandCondition}${id}`)
      setGetTermsData(result.data.data.privacy_policy)
    } catch (error) {
      console.log("errors", error);
    }
  }

  useEffect(() => {
    getTermsCondition()
  }, [])

  useEffect(() => {
    document.title = "Privacy Policy - Treasure Box";
  }, []);
  return (
    <Box
      className="termsOutsideClass"
      sx={{

        padding: { xs: 2, sm: 3, md: 4 },
        margin: { xs: 1, sm: 2, md: 3 },
        borderRadius: '8px',
      }}
    >
      <Box
        className="termsMainClass"
        sx={{
          backgroundColor: 'white',
          padding: { xs: 2, sm: 3, md: 4 },
          boxShadow: 3,
          borderRadius: '12px',
        }}
      >
        <Typography
          className="headTermsClass"
          // variant="h4"
          sx={{
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: 2,
          }}
        >
         Privacy Policy
        </Typography>

        <Typography
          className="terms_content"
          sx={{
            fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' },
            textAlign: 'justify',
          }}
        >
          <RemoveTag ParserText={getTermsData} />
        </Typography>
      </Box>
    </Box>
  )
}
export default Privacy;