import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { ImageUrl } from "../../API/baseUrl";
import { Link } from "react-router-dom";
import ButtonForAll from './../../components/ButtonForALL/index';

const TwoConSlider = ({ titleList = [] }) => {
  // Filter to get items at index 4 and 5
  const filteredTitleList = titleList.slice(3, 7);

  return (
    <Container className="HomepageSliderCon2">
   
      <Row>
        {filteredTitleList.length > 0 ? (
          filteredTitleList.map((item, index) => (
            <Col key={index} xs={12} md={12} lg={5} className="mb-4">
              <div
                className="box box1 d-flex flex-column justify-content-between align-items-center"
                style={{
                  backgroundColor: "rgb(240, 240, 240)",
                  padding: "15px",


                }}
              >
                <Link to="/product" style={{ textDecoration: "none" }}>
                  <div className="box-content d-flex flex-lg-row flex-column align-items-center text-center">
                    <img
                      src={`${ImageUrl}${item.files}`}
                      alt={item.category_name}
                      className="homepagesliderimage mb-3"
                      style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }}
                    />
                    <div className="mb-2">
                      <p
                        className="category-name"
                        style={{
                          color: "var(--primary-color)",
                          marginBottom: "10px",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textAlign:"left"
                        }}
                      >
                        {item.category_name}
                      </p>
                      <Link to="/product" style={{ textDecoration: "none" }}>
                        <ButtonForAll name="Buy Now" />
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
          ))
        ) : (
          <Col xs={12}>
            <p className="text-center">No items available</p>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default TwoConSlider;
