// import { Typography } from "@mui/material";
// import { Link } from "react-router-dom";
// import './index.css';

// export const BreadcrumbsFunction = ({ link1, link2, link3, link4 ,product,page}) => {
//     console.log(link1,link2,link3,link4,product,page ,".000.")  
//     console.log(product,".00")
//     const renderLink = (to, link, key) => {
//         return link ? (
//             <>
//                 <Link to={to} key={key} className="breadcrumbsLink">
//                     {link}
//                 </Link>
              
//                 <span className="separator">›</span>
//             </>
//         ) : null;
//     };

//     return (
//         <div className="breadcrumbsContainer">
//             {renderLink("/", link1, "1")}
//             {renderLink(`/${product}`, product, "5")}
//             {renderLink("/", page, "5")}
//             {renderLink("/", link2, "2")}
//             {renderLink(`/${link3}`, link3, "3")}
//             {renderLink(`/${link4}`, link4, "4")}
//         </div>
//     );
// };
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import './index.css';

export const BreadcrumbsFunction = ({ link1, link2, link3, link4, product, page }) => {
    console.log(link1, link2, link3, link4, product, page, ".000.");
    console.log(product, ".00");

    const renderLink = (to, link, key, isLast) => {
        if (!link) return null;

        // If it's the last link, render it as plain text
        if (isLast) {
            return (
                <Typography key={key} className="breadcrumbsLink">
                    {link}
                </Typography>
            );
        }

        // Otherwise, render it as a clickable link
        return (
            <>
                <Link to={to} key={key} className="breadcrumbsLink">
                    {link}
                </Link>
                <span className="separator">›</span>
            </>
        );
    };

    // Calculate the number of non-null links to determine the last one
    const links = [link1, link2, link3, link4, product, page];
    const lastLinkIndex = links.filter(link => link).length - 1;

    return (
        <div className="breadcrumbsContainer">
            {renderLink("/", link1, "1", lastLinkIndex === 0)}
            {renderLink(`/${product}`, product, "5", lastLinkIndex === 1)}
            {renderLink("/", page, "5", lastLinkIndex === 2)}
            {renderLink("/", link2, "2", lastLinkIndex === 3)}
            {renderLink(`/${link3}`, link3, "3", lastLinkIndex === 4)}
            {renderLink(`/${link4}`, link4, "4", lastLinkIndex === 5)}
        </div>
    );
};
