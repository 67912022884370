import { initializeApp } from "firebase/app";
import { getMessaging,getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDynb-ztFfX2xC8o-zI0h04inrlatrVCgo",
  authDomain: "treasurebox-70ef6.firebaseapp.com",
  projectId: "treasurebox-70ef6",
  storageBucket: "treasurebox-70ef6.appspot.com",
  messagingSenderId: "175205351575",
  appId: "1:175205351575:web:51e9082fd17f28123669d2",
  measurementId: "G-Y9W00L7142"
  };

  initializeApp(firebaseConfig);
  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);
  
  export const onMessageListener = () =>
  
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
  
  export const getNotificationToken = (setTokenFound) => {
    return getToken(messaging, {vapidKey: 'BGAW5bfTE3nGJdNlhTldAijEkIWe9Vqqn8ICYN0U7o--t67orHE8R29-I278IYhL22hdJFOZgHxHv8804gmHeJo'}).then((currentToken) => {
      console.log("firebasetoken" , currentToken)
      localStorage.setItem("device_token", currentToken)
      if (currentToken) {
        setTokenFound(true);
      } else {
        setTokenFound(false);
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }