import React, { useEffect, useState } from "react";
import "./index.css";
import Crousal from "../../components/crousal";
import { FaArrowTurnUp } from "react-icons/fa6";

import {
  Activecategory,
  ImageUrl,
  getApiCall,
  getTitle,
  getfeaturedlist,
  gethometitleApi,
  newarrival,
  postApiCall,
} from "../../API/baseUrl";
import { useNavigate } from "react-router-dom";
import serviceicon1 from "../../Assect/leftimg1.avif";
import serviceicon2 from "../../Assect/service2.avif";
import serviceicon3 from "../../Assect/service3.png";
import serviceicon4 from "../../Assect/return-box.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderComponent from "../../Componentsnew/slider";
import { Box, Skeleton, Typography } from "@mui/material";
import Homeskeleton from "./homeskeleton";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import HomePageSlider from "../../slider/HomePageSlider";
import HoomepageSlider2 from "../../slider/HomePageSlider2";
import BacktoHome from "../../components/backtohome";
import TwoConSlider from "../../slider/twoconSlider";
import Footer from "../../components/footer";
import GetDiscountInfo from "../../Componentsnew/GetDiscountInfo/Index";
import { Container } from "react-bootstrap";
import { Grid } from "react-loader-spinner";
import { Divider } from "semantic-ui-react";

const Newhome = ({ reload, setReload, catval, setCatval }) => {
  const user_id = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [pending, setPending] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [newarrivalList, setNewarrivalList] = useState([]);
  const [featuredList, setFeaturedList] = useState([]);
  const [titleList, setTitleList] = useState([]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchcategoryList = async () => {
    setPending(true);
    try {
      const result = await getApiCall(Activecategory);

      if (result.data.status) {
        setCategoryList(result.data.category);
        setPending(false);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchcategoryList();
  }, []);

  const handlecategory = (id, name) => {
    navigate(`/product/${id}/${name}`);
    setCatval(name);
  };

  const handlesubcategory = (id, name, parentid, catname) => {
    navigate(`/product/${name}/${id}/${parentid}`);
    setCatval(catname);
  };

  const fetchnewarrivalList = async () => {
    setPending(true);
    try {
      const result = await postApiCall(newarrival, {
        userId: user_id ? user_id : "",
      });

      if (result.data.status) {
        setNewarrivalList(result.data.list);
        setPending(false);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setPending(false);
    }
  };

  const fetchfeaturedList = async (id) => {
    setPending(true);
    try {
      const payload = {
        userId: user_id ? user_id : "",
      };

      const result = await postApiCall(getfeaturedlist, payload);
      if (result.data.status) {
        setFeaturedList(result.data.List);
        console.log("featuredList", result.data.List);
        setPending(false);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchnewarrivalList();
    fetchfeaturedList();
  }, [user_id]);

  const handlegetTitle = async () => {
    setPending(true);
    try {
      const result = await getApiCall(gethometitleApi);
      if (result.data.status) {
        setTitleList(result.data.allData);
        setPending(false);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    handlegetTitle();
  }, []);

  return (
    <>
      {/* <Crousal /> */}
      <Container className="home_body_container">
        {pending ? (
          <Homeskeleton />
        ) : (
          <>
            <Box mb={4}>
              <HomePageSlider />
            </Box>
            {featuredList.length ? (
              <Box
                className="home_body_product_container"
                data-aos="fade-right"
                mb={4}
              >
                <HoomepageSlider2 titleList={titleList} />
                <Grid container spacing={2} justifyContent="center" alignItems="center" mt={6}>
                  <Grid item xs={2}>
                    <Divider className="headingLine1" sx={{ width: '20%' }} />
                  </Grid>
                  <Grid item xs={8} textAlign="center">
                    <Typography
                      variant="h2"
                      className="home_body_product_heading"
                      sx={{ color: 'var(--heading-color)' }}
                    >
                      Trending Products
                      {/* Best Sellers */}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Divider className="headingLine1" sx={{ width: '20%' }} />
                  </Grid>
                </Grid>
                <Typography variant="body1" textAlign="center" mb={5}>
                  <div
                    class="row  d-flex"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      marginTop: "50px",

                      width: "90%",
                      margin: "auto",
                    }}
                  >
                    <hr class="headingLine1 col-2" style={{ width: "20%" }} />

                    <h2
                      class="home_body_product_heading col "
                      style={{
                        color: "var(--heading-color)",
                        textAlign: "center",
                        width: "30%",
                      }}
                    >
                      Trending Products
                      {/* Best Sellers */}
                    </h2>

                    <hr class="headingLine1 col-2" style={{ width: "20%" }} />
                  </div>

                  Discover the latest in-demand products everyone's talking about
                </Typography>
                <Box className="home_body_product_head_container">
                  <SliderComponent
                    newarrivalList={featuredList}
                    reload={reload}
                    setReload={setReload}
                    setNewarrivalList={setFeaturedList}
                  />
                </Box>
              </Box>
            ) : null}
            <Box className="home_body_product_container">
              <Box className="home_body_product_head_container" mb={4}>

                <Typography variant="body1" textAlign="center" mb={5}>
                <div
                      class="row  d-flex"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "100px",
                        width: "90%",
                        margin: "auto",
                      }}
                    >
                      <hr class="headingLine1 col" style={{ width: "80px" }} />

                      <h1
                        class="home_body_product_heading col "
                        style={{
                          color: "var(--heading-color)",
                          textAlign: "center",
                          fontSize: "50px",
                        }}
                      >
                        New Arrival
                      </h1>

                      <hr class="headingLine1 col" style={{ width: "80px" }} />
                    </div>

                  Check out our latest arrivals – just in time for you
                </Typography>
                <Box className="newarrival_container">
                  <SliderComponent
                    newarrivalList={newarrivalList}
                    setNewarrivalList={setNewarrivalList}
                    reload={reload}
                    setReload={setReload}
                  />
                </Box>
                <TwoConSlider titleList={titleList} />
              </Box>
            </Box>
          </>
        )}
      </Container>




      <BacktoHome ></BacktoHome>

    </>
  );
};

export default Newhome;
