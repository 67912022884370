import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import './index.css';

const ReturnPolicy = () => {
  useEffect(() => {
    document.title = "Refund and Cancellation Policy - Treasure Box";
  }, []);

  return (
    <Box
      className="termsOutsideClass"
      sx={{
        padding: { xs: 2, sm: 3, md: 4 },
        margin: { xs: 1, sm: 2, md: 3 },
        borderRadius: '8px',
      }}
    >
      <Box
        className="termsMainClass"
        sx={{
          backgroundColor: 'white',
          padding: { xs: 2, sm: 3, md: 4 },
          boxShadow: 3,
          borderRadius: '12px',
        }}
      >
        <Typography
          className="headTermsClass"
          variant="h4"
          sx={{
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: 2,
          }}
        >
          Refund and Cancellation Policy
        </Typography>

        <Typography
          className="terms_content"
          sx={{
            fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' },
            textAlign: 'justify',
            marginBottom: 2,
          }}
        >
          At Treasure Box (owned by Batra Marketing), we want you to be completely satisfied with your purchase. If you are not happy with your item, we're here to help. The following are the Refund and Cancellation Policies:
        </Typography>

        <Box component="ul" className="returnpolicy_content numberlist">
          <li><span className='headingBold'>Order Cancellation:</span> Cancellations must be made at least 48 hours before the actual delivery time to be eligible for a refund. Once the 48-hour delivery window starts, no refund will be provided. For courier products, cancellations are not possible once dispatched. Personalized products cannot be cancelled once the order is placed.</li>

          <li><span className='headingBold'>Cancellation Request Process: </span>To cancel your order, please send an email with the subject line "Cancellation Request" to <a href="mailto:treasureboxind@gmail.com">treasureboxind@gmail.com</a>. The email should include the following details:
            <Box component="ul" sx={{ marginLeft: 3 }}>
              <li>Order ID</li>
              <li>Cancellation Reason</li>
              <li>Refund Account Details</li>
            </Box>
          </li>

          <li><span className='headingBold'>Cancellation Procedure:</span> The cancellation process will be initiated once we receive your formal cancellation request at <a href="mailto:treasureboxind@gmail.com">treasureboxind@gmail.com</a>.</li>

          <li><span className='headingBold'>Refund Eligibility:</span> Refunds will be issued under the following conditions:
            <Box component="ul" sx={{ marginLeft: 3 }}>
              <li>Items can only be returned if they are eligible for a return.</li>
              <li>Products must be unused, in the same condition you received them, and in their original packaging.</li>
              <li>Return requests must be made within 7 days of receiving the item.</li>
              <li>User must make a video of the product.</li>
            </Box>
          </li>

          <li><span className='headingBold'>Refund Requests: </span>Refunds can be requested only in cases of service failure. Our team will evaluate the request, and the decision made by our management will be final. The refund amount will not exceed the total paid by the customer.</li>

          <li><span className='headingBold'>Non-Refundable Amounts: </span>Any discounted or exempted amounts are non-refundable.</li>

          <li><span className='headingBold'>Non-Returnable Items:</span> Perishable items such as Ceramic cups, mugs, and glass crockery items are non-returnable.</li>

          <li><span className='headingBold'>Final Authority: </span>Treasure Box holds the final authority on all matters related to cancellations and refunds.</li>

          <li><span className='headingBold'>Policy Amendments: </span>Treasure Box reserves the right to amend this Cancellation and Refund Policy at any time. Any significant changes will be communicated via email or announced on our website.</li>

          <li><span className='headingBold'>Questions or Comments:</span> For any questions or comments regarding our Cancellation and Refund Policy, please email us at <a href="mailto:treasureboxind@gmail.com">treasureboxind@gmail.com</a>.</li>
        </Box>

      </Box>
    </Box>
  );
};

export default ReturnPolicy;
