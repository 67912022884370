// import React, { useEffect } from 'react'

// const Shipping = () => {
//   useEffect(() => {
//     document.title = "Shipping Policy - Treasure Box";
//   }, []);

//   return (
//     <div className="termsOutsideClass">
//             <div className="termsMainClass">
//                 <p className="headTermsClass">Shipping Policy</p>
//                 <ul className='returnpolicy_content dotted'>
//                     <li className=''>Treasure Box will ensure that the products are delivered in excellent condition and in the fastest time possible. All orders are shipped within 3-5 working days of placing the order and it usually takes another 2-10 working days for domestic orders (India) to reach your doorsteps (subject to transit conditions).</li>
//                     <li className=''>Although we strive hard to deliver the orders in the given turnaround time (2-4 working days for the ready fabrics) and (7-10 working days for customized fabric orders), however, there could be rare instances wherein the order could get delayed due to circumstances beyond our control.</li>
//                     <li className=''>For products that require separate shipping charges shall be indicated in the product detail page and while the order is being placed.</li>
//                     <li className=''>We can process only one shipping address per order, hence if you would like to ship products to multiple addresses then you are required to place separate orders.</li>
//                     <li className=''>Amount mentioned on the payment page will be the final amount.</li>
//                     <li className=''>Prices are inclusive of all charges and taxes (terms n conditions apply).</li>
//                     <li className=''>The delivery days mentioned are approximate only. Since we rely on our courier partners for delivery, this can vary due to factors that are outside our control.</li>
//                     <li className=''>Once your order has been shipped, you will receive an email notification with the courier and tracking details so that you can track your order online.</li>
//                     <li className=''>for bulk orders shipping and taxes shall be paid by the customer.</li>

//                 </ul>
//             </div>
//         </div>
//   )
// }

// export default Shipping;

import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import './index.css';

const Shipping = () => {
  useEffect(() => {
    document.title = "Shipping Policy - Treasure Box";
  }, []);

  return (
    <Box
      className="termsOutsideClass"
      sx={{
        padding: { xs: 2, sm: 3, md: 4 },
        margin: { xs: 1, sm: 2, md: 3 },
        borderRadius: '8px',
      }}
    >
      <Box
        className="termsMainClass"
        sx={{
          backgroundColor: 'white',
          padding: { xs: 2, sm: 3, md: 4 },
          boxShadow: 3,
          borderRadius: '12px',
        }}
      >
        <Typography
          className="headTermsClass"
          variant="h4"
          sx={{
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: 2,
          }}
        >
          Shipping Policy
        </Typography>

        <Box component="ul" className="returnpolicy_content dotted">
          <li>Treasure Box will ensure that the products are delivered in excellent condition and in the fastest time possible. All orders are shipped within 3-5 working days of placing the order and it usually takes another 2-10 working days for domestic orders (India) to reach your doorsteps (subject to transit conditions).</li>

          <li>Although we strive hard to deliver the orders in the given turnaround time (2-4 working days for the ready fabrics) and (7-10 working days for customized fabric orders), however, there could be rare instances wherein the order could get delayed due to circumstances beyond our control.</li>

          <li>For products that require separate shipping charges, this shall be indicated on the product detail page and during the order placement process.</li>

          <li>We can process only one shipping address per order, hence if you would like to ship products to multiple addresses, then you are required to place separate orders.</li>

          <li>The amount mentioned on the payment page will be the final amount.</li>

          <li>Prices are inclusive of all charges and taxes (terms and conditions apply).</li>

          <li>The delivery days mentioned are approximate only. Since we rely on our courier partners for delivery, this can vary due to factors outside our control.</li>

          <li>Once your order has been shipped, you will receive an email notification with the courier and tracking details so that you can track your order online.</li>

          <li>For bulk orders, shipping and taxes shall be paid by the customer.</li>
        </Box>
      </Box>
    </Box>
  );
};

export default Shipping;
